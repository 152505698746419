import './product-edit-form.less';
import uploadIcon from '../../../assets/product-edit-form/upload-icon.svg';
import MultipleSelectComponent from '../../../components/multiple-select-component/multiple-select-component';
import TextInputComponent from '../../../components/text-input-component/text-input-component';
import { Cannabinoid } from '../../../models/model_brand';
import { Product } from '../../../models/model_product';
import { getProductByIdAPI } from '../../../services/api/productAPI/getProductById';
import { getPotentialDuplicatesAPI } from '../../../services/api/productAPI/potentialDuplicatesAPI';
import { releaseProductLockAPI } from '../../../services/api/productAPI/releaseProductEditLock';
import { ILabResults, ILabResultsInfo } from '../../../types/products';
import { Terpenes, StrainTypes, States, ProductTypes } from '../../../utils/options';
import { getBase64, getSizeInUnits, Net } from '../../../utils/utils';
import PotentialDuplicatesModal from '../potential_duplicates_modal/potential_duplicates_modal';
import BrandSelect from '../product_table/components/BrandSelect';
import { Button, Form, Upload, notification, Typography, Select, Checkbox, Input } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import React, { useState, useEffect, useCallback } from 'react';

const { TextArea } = Input;

const metricMeasurements = {
  smokeable: ['flowerSmoke', 'vapeOil', 'dabbableConcentrates'],
  nonSmokeable: ['edibles', 'drink', 'topical', 'intimateProducts', 'tincture'],
};

const { Option } = Select;

interface ProductFormProp {
  visible: boolean;
  hide: () => void;
  onSuccess: (id?: string) => void;
  selectedProduct: any;
  action: string;
  cannabinoidsList: Cannabinoid[];
  avoidPendingCreation?: boolean;
}

interface FormData {
  id: string;
  inner_id: string;
  brand: string;
  brand_name: string;
  name: string;
  product_type: string;
  flavor: string;
  strain_type: string;
  strain: string;
  THC: string;
  CBD: string;
  THCa: string;
  THCv: string;
  CBDa: string;
  CBDv: string;
  CBN: string;
  CBG: string;
  CBC: string;
  CBCv: string;
  'Delta-8'?: string;
  Delta10?: string;
  HHC?: string;
  THCp?: string;
  THCo?: string;
  HXC?: string;
  HXCp?: string;
  CBT?: string;
  terpenes: string;
  cannabinoid: string;
  ex_store_url: string;
  covers: string;
  legal_states: string;
  note: string;
  action: string;
  dose_required: boolean;
  dosage: any;
  link?: string;
  updatedAt?: string;
  reviewRequired?: boolean;
}

const ProductEditForm = (props: ProductFormProp) => {
  const { selectedProduct, action, avoidPendingCreation = true } = props;
  const {
    id,
    inner_id,
    brand,
    brand_name,
    legal_states,
    name,
    product_type,
    strain,
    strain_type,
    terpenes,
    ex_store_url,
    THC,
    CBD,
    THCa,
    THCv,
    CBDa,
    CBDv,
    CBN,
    CBG,
    CBC,
    CBCv,
    note,
    flavor,
    reviewRequired,
    labResults,
    retailProductCannabinoids,
    retailProductTerpenes,
  } = selectedProduct;

  const [actionValue, setActionValue] = useState('pending-edit');

  const getJointlyCannabinoid = () => {
    const jointlyCannabinoidsLabResults =
      labResults?.filter(
        (item: ILabResults) =>
          !item.retailLocationId && !item.retailProductId && item.type === 'cannabinoid'
      ) ?? [];

    const retailCannabinoidsLabResults =
      labResults?.filter(
        (item: ILabResults) =>
          item.retailLocationId && item.retailProductId && item.type === 'cannabinoid'
      ) ?? [];

    const groupedArray: ILabResultsInfo[] = [];

    for (const item of retailCannabinoidsLabResults) {
      const { name, min, max } = item;

      const foundItem = groupedArray.find((e) => e.name === name);

      if (!foundItem) {
        groupedArray.push({ name, min, max });
      } else {
        if (min !== null && (foundItem.min === null || min < foundItem.min)) {
          foundItem.min = min;
        }

        if (max !== null && (foundItem.max === null || max > foundItem.max)) {
          foundItem.max = max;
        }
      }
    }

    const jointlyCannabinoids: string[] = [];

    jointlyCannabinoidsLabResults.forEach((item: ILabResults) => {
      jointlyCannabinoids.push(item.name);
    });
    return {
      labResults: jointlyCannabinoidsLabResults,
      retail: groupedArray,
      cannabinoids: jointlyCannabinoids,
    };
  };

  const getJointlyTerpene = () => {
    const jointlyTerpenesLabResults =
      labResults?.filter(
        (item: ILabResults) =>
          !item.retailLocationId && !item.retailProductId && item.type === 'terpene'
      ) ?? [];

    const retailTerpenesLabResults =
      labResults?.filter(
        (item: ILabResults) =>
          item.retailLocationId && item.retailProductId && item.type === 'terpene'
      ) ?? [];

    const groupedArray: ILabResultsInfo[] = [];

    for (const item of retailTerpenesLabResults) {
      const { name, min, max } = item;

      const foundItem = groupedArray.find((e) => e.name === name);

      if (!foundItem) {
        groupedArray.push({ name, min, max });
      } else {
        if (min !== null && (foundItem.min === null || min < foundItem.min)) {
          foundItem.min = min;
        }

        if (max !== null && (foundItem.max === null || max > foundItem.max)) {
          foundItem.max = max;
        }
      }
    }

    const jointlyTerpenes: string[] = [];

    jointlyTerpenesLabResults.forEach((item: ILabResults) => {
      jointlyTerpenes.push(item.name);
    });
    return {
      labResults: jointlyTerpenesLabResults,
      retail: groupedArray,
      terpenes: jointlyTerpenes,
    };
  };

  const [previewImage, setPreviewImage] = useState('');
  const [adminImage, setAdminImage] = useState(null);
  const [imageFormat, setImageFormat] = useState('');
  const [imageSize, setImageSize] = useState('');
  const [approveModalLoading, setApproveModalLoading] = useState(false);
  const [cannabinoidValue, setCannabinoidValue] = useState([
    ...getJointlyCannabinoid().cannabinoids,
  ]);
  const [cannabinoidLabResultsValue, setCannabinoidLabResultsValue] = useState([
    ...getJointlyCannabinoid().labResults,
  ]);

  const [terpeneLabResultsValue, setTerpeneLabResultsValue] = useState([
    ...getJointlyTerpene().labResults,
  ]);

  const [terpeneValue, setTerpeneValue] = useState([...getJointlyTerpene().terpenes]);

  const [showDuplicates, setShowDuplicates] = useState(false);
  const [productType, setProductType] = useState(selectedProduct?.product_type?.type);
  const [productSpecific, setProductSpecific] = useState(selectedProduct?.product_type?.specific);
  const [form] = Form.useForm();
  const [potentialDuplicates, setPotentialDuplicates] = useState([]);

  const getPotentialDuplicates = (productId: string) =>
    getPotentialDuplicatesAPI(productId).then((response) => setPotentialDuplicates(response?.data));

  const onAutoFillStates = useCallback(
    (product: Product) =>
      getProductByIdAPI(product.id).then((response) => {
        form.setFieldsValue({
          legal_states: response?.data?.brand_legal_states,
        });
      }),
    [form]
  );

  useEffect(() => {
    if (selectedProduct?.legal_states?.length === 0) {
      onAutoFillStates(selectedProduct);
    }
    if (id) getPotentialDuplicates(id);
  }, [id, onAutoFillStates, selectedProduct]);

  const switchDuplicatesModal = (refreshData?: boolean) => {
    setShowDuplicates(!showDuplicates);
    if (refreshData) props.onSuccess();
  };

  const closeEditModal = () => {
    releaseProductLockAPI(id);
    props.hide();
  };

  useEffect(
    () => () => {
      // When id changed or component unmounts, release the product
      releaseProductLockAPI(id);
    },
    [id]
  );

  const getSpecificOptions = () => {
    if (!productType) {
      return [];
    }
    const type = ProductTypes.find(({ key }) => key === productType);
    if (!type) {
      return [];
    }
    Object.values(type.specific).forEach((val, index) => {
      val.key = Object.keys(type.specific)[index];
    });

    return Object.values(type.specific);
  };

  const getDosageValues = () => {
    const specificOptions = getSpecificOptions();
    return (
      specificOptions.find(({ key }) => productSpecific === key) || {
        dosageRequired: false,
        dosageValue: '',
      }
    );
  };

  const approveNewProduct = async (values: FormData) => {
    const newLabResults = [...terpeneLabResultsValue, ...cannabinoidLabResultsValue].map((item) => {
      if (item.min === null && item.max !== null) {
        item.min = item.max;
      } else if (item.min !== null && item.max === null) {
        item.max = item.min;
      }
      return item;
    });

    const { id, inner_id, updated_at, covers } = selectedProduct;
    const {
      name,
      brand_name,
      product_type,
      strain,
      strain_type,
      flavor,
      ex_store_url,
      legal_states,
      note,
      specific,
      reviewRequired,
    } = values as any;

    const formData = {
      id,
      inner_id,
      brand_name,
      name,
      product_type: JSON.stringify({
        type: product_type,
        specific,
      }),
      flavor,
      strain_type,
      strain,
      ex_store_url,
      covers: JSON.stringify(covers),
      legal_states: JSON.stringify(
        legal_states.includes('all') ? States.map((s) => s.abbreviation) : legal_states
      ),
      note,
      dose_required: getDosageValues()?.dosageRequired,
      dosage: getDosageValues().dosageRequired ? { unit: getDosageValues()?.dosageValue } : {},
      link: covers?.[0]?.link,
      action: actionValue,
      updatedAt: updated_at,
      reviewRequired: reviewRequired || false,
      labResults: newLabResults,
    };

    setApproveModalLoading(true);

    try {
      if (adminImage) {
        const adminImageRes = await Net.req(
          '/product/admin-upload-image',
          {
            name: inner_id,
            adminImage,
          },
          'multipart'
        );
        if (adminImageRes?.link) {
          formData.link = adminImageRes.link;
        } else if (adminImageRes.status === 500) {
          setApproveModalLoading(false);
          return;
        }
      }

      if (!formData.link) {
        notification['error']({
          message: 'Please upload the images.',
        });
        setApproveModalLoading(false);
        return;
      }

      const res = await Net.req('/product/admin-update-product', formData, 'post');
      setApproveModalLoading(false);
      if (res?.status) {
        if (res.status === 'success') {
          props.hide();
          form.resetFields();
          props.onSuccess?.(res?.product?.id);
          notification['success']({
            message: 'Success!',
          });
        } else {
          notification['error']({
            message: res?.message ?? 'Something went wrong!',
          });
        }
      } else {
        notification['error']({
          message: res?.message ?? 'Something went wrong!',
        });
      }
    } catch (e: unknown) {
      setApproveModalLoading(false);
      let errorMessage = 'Something went wrong!';
      if (typeof e === 'object' && e !== null && 'response' in e) {
        const errorObj = e as { response: { message?: string } };
        if (errorObj.response.message) {
          errorMessage = errorObj.response.message;
        }
      }
      notification['error']({
        message: errorMessage,
      });
    }
  };

  const uploadFile = (file: RcFile) => {
    getBase64(file).then((res) => {
      setPreviewImage(res as any);

      setImageSize((file.size / 1000).toString());
      setImageFormat(file.type.split('/')[1].toUpperCase());
    });

    setAdminImage(file as any);
    return false;
  };

  const removeFile = () => {
    setPreviewImage('');
    setAdminImage(null);
  };

  const fileDialogProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    fileList: [],
    beforeUpload: uploadFile,
    onRemove: removeFile,
  };

  const submitForm = (values: FormData) => {
    approveNewProduct(values);
  };

  const handleBlurCannabinoid = (itemName: string) => {
    const copyCannabinoidLabResultsValue: ILabResults[] = [];
    cannabinoidLabResultsValue.forEach((item) => {
      if (item.name === itemName) {
        item.min = item.min ? parseFloat(item.min) : null;
        item.max = item.max ? parseFloat(item.max) : null;
        if (item.max === null) {
          item.max = item.min;
          const element: any = document.getElementById(`${item.name}-max`);
          if (element) {
            element.value = item.min;
          }
        }
      }
      copyCannabinoidLabResultsValue.push(item);
    });
    setCannabinoidLabResultsValue([...copyCannabinoidLabResultsValue]);
  };

  const handleChangeCannabinoid = (itemName: string, value: string, type: string) => {
    const copyCannabinoidLabResultsValue = cannabinoidLabResultsValue.map((item) => {
      if (item.name === itemName) {
        item[type] = value === '' ? null : value;
      }

      return item;
    });
    setCannabinoidLabResultsValue([...copyCannabinoidLabResultsValue]);
  };

  const handleChangeSelectCannabinoid = (cannabinoidArray: string[]) => {
    const remainingItens: ILabResults[] = [];
    cannabinoidArray.forEach((item) => {
      const exists = cannabinoidLabResultsValue.some((obj) => obj.name === item.replace('mg', ''));
      if (!exists) {
        cannabinoidLabResultsValue.push({ name: item, type: 'cannabinoid', min: null, max: null });
      }
    });

    cannabinoidLabResultsValue.forEach((item: ILabResults) => {
      if (cannabinoidArray.includes(item.name)) {
        remainingItens.push(item);
      }
    });
    setCannabinoidLabResultsValue([...remainingItens]);
    setCannabinoidValue([...cannabinoidArray]);
  };

  const handleBlurTerpene = (itemName: string) => {
    const copyTerpeneLabResultsValue: ILabResults[] = [];
    terpeneLabResultsValue.forEach((item) => {
      if (item.name === itemName) {
        item.min = item.min ? parseFloat(item.min) : null;
        item.max = item.max ? parseFloat(item.max) : null;
        if (item.max === null) {
          item.max = item.min;
          const element: any = document.getElementById(`${item.name}-max`);
          if (element) {
            element.value = item.min;
          }
        }
      }

      copyTerpeneLabResultsValue.push(item);
    });
    setTerpeneLabResultsValue([...copyTerpeneLabResultsValue]);
  };

  const handleChangeTerpene = (itemName: string, value: string, type: string) => {
    const copyTerpeneLabResultsValue = terpeneLabResultsValue.map((item) => {
      if (item.name === itemName) {
        item[type] = value === '' ? null : value;
      }
      return item;
    });
    setTerpeneLabResultsValue([...copyTerpeneLabResultsValue]);
  };

  const handleChangeSelectTerpene = (terpeneArray: string[]) => {
    const remainingItens: ILabResults[] = [];
    terpeneArray.forEach((item) => {
      const exists = terpeneLabResultsValue.some((obj) => obj.name === item);
      if (!exists) {
        terpeneLabResultsValue.push({ name: item, type: 'terpene', min: null, max: null });
      }
    });

    terpeneLabResultsValue.forEach((item: ILabResults) => {
      if (terpeneArray.includes(item.name)) {
        remainingItens.push(item);
      }
    });
    setTerpeneLabResultsValue([...remainingItens]);
    setTerpeneValue([...terpeneArray]);
  };

  const handleChangeProductType = (value: string | string[]) => {
    setProductType(value);
    form.setFieldsValue({ specific: undefined });
  };

  const handleUpdateFieldsFromJSONData = async () => {
    try {
      // Get the JSON string from the JSON_product_data field
      const jsonData = form.getFieldValue('JSON_product_data');

      // Parse the JSON string to an object
      const parsedData = JSON.parse(jsonData);

      // Update all form fields with the parsed JSON data
      form.setFieldsValue({
        ...parsedData,
        product_type: parsedData.product_type?.type,
        specific: parsedData.product_type?.specific,
        link: parsedData.covers?.[0]?.link,
      });

      setCannabinoidValue(
        parsedData.labResults
          ?.filter((item: ILabResults) => item.type === 'cannabinoid')
          .map((item: ILabResults) => item.name)
      );

      setTerpeneValue(
        parsedData.labResults
          ?.filter((item: ILabResults) => item.type === 'terpene')
          .map((item: ILabResults) => item.name)
      );

      setCannabinoidLabResultsValue(
        parsedData.labResults?.filter((item: ILabResults) => item.type === 'cannabinoid') ?? []
      );

      setTerpeneLabResultsValue(
        parsedData.labResults?.filter((item: ILabResults) => item.type === 'terpene') ?? []
      );

      const imageUrl = parsedData.covers?.[0]?.link;

      setPreviewImage(imageUrl);

      // Fetch the image and convert it to a binary file
      if (imageUrl) {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], 'admin-image.jpg', { type: blob.type });

          // Set adminImage to the binary file
          setAdminImage(file as any);
        } catch (error) {
          notification.error({ message: 'Error updating image' });
        }
      } else {
        setAdminImage(null);
      }

      notification.success({ message: 'Fields updated successfully!' });
    } catch (error) {
      notification.error({ message: 'Invalid JSON data.' });
    }
  };

  const onAutoFillForm = async ({ value, label }: { value: string; label: string }) => {
    if (value) {
      let autoFillData = {};
      let response;
      try {
        response = await Net.req('/product/recent-product-by-brand', {
          brand_id: value,
        });
      } catch (error) {
        console.error('error', error);
      }

      if (response) {
        const { ex_store_url, legal_states } = response;

        autoFillData = {
          ex_store_url: ex_store_url || '',
          legal_states: legal_states || [],
          brand_name: label,
          brand: value,
        };

        updateJsonProductData({}, autoFillData);
      }
      form.setFieldsValue({
        ...autoFillData,
      });
    }
  };

  useEffect(() => {
    if (!(ex_store_url && legal_states)) {
      onAutoFillForm({ value: brand, label: brand_name });
    } else {
      form.setFieldsValue({
        ex_store_url,
        legal_states,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    brand,
    brand_name,
    legal_states,
    name,
    product_type: product_type?.type,
    specific: product_type?.specific,
    strain,
    strain_type,
    terpenes,
    id,
    inner_id,
    ex_store_url,
    THC,
    CBD,
    THCa,
    THCv,
    CBDa,
    CBDv,
    CBN,
    CBG,
    CBC,
    CBCv,
    note,
    flavor,
    reviewRequired,
    'Delta-8': selectedProduct['Delta-8'],
    Delta10: selectedProduct.Delta10,
    HHC: selectedProduct.HHC,
    THCp: selectedProduct.THCp,
    THCo: selectedProduct.THCo,
    HXC: selectedProduct.HXC,
    HXCp: selectedProduct.HXCp,
    CBT: selectedProduct.CBT,
    JSON_product_data: JSON.stringify({
      ...selectedProduct,
      retailProductCannabinoids: undefined,
      retailProductTerpenes: undefined,
      labResults: labResults?.filter(
        (item: ILabResults) => !item.retailLocationId && !item.retailProductId
      ),
      id: undefined,
      inner_id: undefined,
      brand: undefined,
      cannabinoid: undefined,
      terpenes: undefined,
      status: undefined,
      note: undefined,
      dose_required: undefined,
      created_at: undefined,
      updated_at: undefined,
      submitted_at: undefined,
      completed_at: undefined,
      last_updated_by: undefined,
      reviewRequired: undefined,
      product_type: {
        type: product_type?.type,
        specific: product_type?.specific,
      },
      specific: undefined,
    }),
  };

  const getSaveAction = () => {
    if (action === 'new') return 'new';
    if (selectedProduct.status === 'pending') return 'pending-edit';
    return 'edit';
  };

  // Callback to update JSON Product Data
  const updateJsonProductData = useCallback(
    (changedValues: Partial<FormData>, allValues: any) => {
      if (Object.keys(changedValues)[0] === 'JSON_product_data') return;
      const filteredValues = Object.fromEntries(
        Object.entries(allValues).filter(([_, value]) => value !== null && value !== undefined)
      );
      delete filteredValues.JSON_product_data;
      delete filteredValues.specific;
      const jsonData = JSON.stringify({
        ...JSON.parse(form.getFieldValue('JSON_product_data')),
        ...filteredValues,
        product_type: {
          type: form.getFieldValue('product_type'),
          specific: form.getFieldValue('specific'),
        },
        labResults: cannabinoidLabResultsValue.concat(terpeneLabResultsValue),
        brand: undefined,
        reviewRequired: undefined,
      });
      form.setFieldsValue({ JSON_product_data: jsonData });
    },
    [form, cannabinoidLabResultsValue, terpeneLabResultsValue]
  );

  useEffect(() => {
    updateJsonProductData(
      {},
      {
        labResults: [...cannabinoidLabResultsValue, ...terpeneLabResultsValue],
      }
    );
  }, [cannabinoidLabResultsValue, terpeneLabResultsValue, updateJsonProductData]);

  const onAutoFillStateForm = async (e: string[]) => {
    let updatedStates = e;
    if (e.includes('all')) {
      updatedStates = States.map((item) => item.abbreviation);
    }
    form.setFieldsValue({
      legal_states: updatedStates,
    });
    updateJsonProductData(
      {},
      {
        legal_states: updatedStates,
      }
    );
  };

  return (
    <Form
      form={form}
      onFinish={submitForm}
      className="edit-product-background"
      initialValues={initialValues}
      onValuesChange={updateJsonProductData} // Track changes to update JSON data
    >
      {showDuplicates && (
        <PotentialDuplicatesModal
          id={id}
          hideDuplicatesModal={switchDuplicatesModal}
          potentialDuplicates={potentialDuplicates}
        />
      )}
      <div className="product-edit-parent">
        <Upload {...fileDialogProps} className="product-upload-button">
          <div className="product-image-section">
            <img
              src={previewImage || (props.selectedProduct as Product).covers?.[0]?.link}
              alt="product"
              className="product-image"
            />
            <div className="product-upload-button">
              <img
                src={uploadIcon}
                className="upload-button-icon"
                id="add-product-image"
                alt="add-product"
              />
              Upload
            </div>
            {previewImage && (
              <div className="product-edit-image-text">
                {imageFormat} - {getSizeInUnits(imageSize)}
                <br />
                Size: {document.getElementById('add-product-image')?.clientWidth}x
                {document.getElementById('add-product-image')?.clientHeight}
                px
              </div>
            )}
            <div className="product-edit-bottom-image-description">
              Image is best if 200x200px min. Format .jpg, .png
            </div>
          </div>
        </Upload>
        <div className="product-edit-section-2">
          <div className="product-edit-sub-section-2">
            <div className="edit-product-header">
              <div className="edit-product-id">
                ID #{selectedProduct.inner_id}
                {action === 'pending-edit' && (
                  <>
                    <br />
                    <br />

                    <Button
                      data-testid="custom-element"
                      type="primary"
                      disabled={approveModalLoading}
                      onClick={() => switchDuplicatesModal()}
                    >
                      Show Potential Duplicates ({potentialDuplicates?.length || 0})
                    </Button>

                    <br />
                    <br />
                    <b>
                      Number of reports{' '}
                      {potentialDuplicates.reduce(
                        (acc: number, val: { reportsCount: number }) => acc + val.reportsCount,
                        0
                      )}
                    </b>
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className="edit-product-option-buttons">
              <Button type="link" onClick={closeEditModal}>
                Cancel
              </Button>
              <Button
                type="link"
                className={'approve-btn'}
                onClick={handleUpdateFieldsFromJSONData}
              >
                Update from JSON
              </Button>
              {avoidPendingCreation && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={approveModalLoading}
                  disabled={approveModalLoading}
                  onClick={() => setActionValue(getSaveAction())}
                >
                  Save
                </Button>
              )}
              {selectedProduct.status !== 'active' && (
                <Button
                  type="link"
                  htmlType="submit"
                  onClick={() => {
                    if (avoidPendingCreation) {
                      setActionValue('approve');
                    } else {
                      setActionValue('new');
                    }
                  }}
                  loading={approveModalLoading}
                  disabled={approveModalLoading}
                  className={'approve-btn'}
                >
                  Approve
                </Button>
              )}
            </div>
          </div>
          <div className="data-row-2">
            <div>
              <TextInputComponent
                name="name"
                placeholder="Product Name"
                title="Product Name"
                size={'366px'}
                required={true}
                data-testid="product-name"
              />

              <div className="select-container-1">
                <Typography.Text>Brand Name</Typography.Text>
                <Form.Item name="brand" rules={[{ required: true }]}>
                  <BrandSelect
                    onSelect={(option) => {
                      onAutoFillForm(option);
                    }}
                    defaultBrand={form.getFieldValue('brand_name')}
                  />
                </Form.Item>
                <Form.Item name="brand_name" hidden>
                  <Input />
                </Form.Item>
              </div>

              <div className="select-container-1">
                <Typography.Text>Legal States</Typography.Text>
                <Form.Item name="legal_states" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    data-testid="legal-states"
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={onAutoFillStateForm}
                    filterOption={(input, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key="all" value="all">
                      All
                    </Option>
                    {States.map(({ name, abbreviation }) => (
                      <Option key={abbreviation} value={abbreviation}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <Button type="primary" onClick={() => form.setFieldsValue({ legal_states: [] })}>
                Clear All States
              </Button>

              <div className="select-container-1">
                <Typography.Text>Cannabinoid</Typography.Text>
                <Form.Item
                  rules={[
                    {
                      required: getDosageValues()?.dosageRequired,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={'Cannabinoid'}
                    optionFilterProp="children"
                    mode={'multiple'}
                    onChange={(e) => handleChangeSelectCannabinoid(e)}
                    value={cannabinoidValue}
                    filterOption={(input, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    data-testid="cannabinoid"
                  >
                    <Option key="all" value="" disabled>
                      Cannabinoid
                    </Option>
                    {props.cannabinoidsList.map((item: any) => (
                      <Option key={item['key']} value={item['key']}>
                        {item['name']}
                      </Option>
                    ))}
                  </Select>
                  <div>
                    {retailProductCannabinoids &&
                      Object.entries(retailProductCannabinoids).map(([key, value]) => (
                        <div style={{ paddingLeft: '20px' }} key={key}>
                          {key} {':'} {value}
                        </div>
                      ))}
                  </div>
                </Form.Item>
              </div>

              <div className="select-container-1">
                <Typography.Text>Terpene</Typography.Text>
                <Form.Item>
                  <Select
                    showSearch
                    placeholder={'Terpenes'}
                    optionFilterProp="children"
                    mode={'multiple'}
                    onChange={(e) => handleChangeSelectTerpene(e)}
                    value={terpeneValue}
                    filterOption={(input, option: any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    data-testid="terpene"
                  >
                    <Option key="all" value="" disabled>
                      Terpene
                    </Option>
                    {Terpenes.map((item: any) => (
                      <Option key={item['key']} value={item['key']}>
                        {item['key']}
                      </Option>
                    ))}
                  </Select>
                  <div>
                    {retailProductTerpenes &&
                      Object.entries(retailProductTerpenes).map(([key, value]) => (
                        <div style={{ paddingLeft: '20px' }} key={key}>
                          {key} {':'} {value}
                        </div>
                      ))}
                  </div>
                </Form.Item>
              </div>

              <div className="product-edit-section-3">
                <div className="product-edit-row">
                  <TextInputComponent
                    name="ex_store_url"
                    data-testid="store-url"
                    placeholder="Store URL"
                    title="Store URL"
                    size={'366px'}
                    required={true}
                  />
                </div>
                <div className="product-edit-row">
                  <TextInputComponent
                    name="note"
                    placeholder="Note"
                    title="Note"
                    data-testid="note"
                    size={'366px'}
                    required={false}
                  />
                </div>
                <div className="product-edit-row">
                  <Form.Item name="reviewRequired" valuePropName="checked">
                    <Checkbox>New Cannabinoids</Checkbox>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <div className="product-edit-section-3-parent">
                <div className="product-edit-section-3">
                  <div className="product-edit-layer-1">
                    <div className="product-edit-row">
                      <div className="select-container-1 specific-modal">
                        <MultipleSelectComponent
                          dataTestid="product-type"
                          name="product_type"
                          placeholder="Product Type"
                          onChange={handleChangeProductType}
                          title="Product Type"
                          options={ProductTypes}
                          keyItem={'key'}
                          valueItem={'name'}
                          multiple={false}
                          size={'179px'}
                          required={true}
                        />
                      </div>

                      <div className="product-spacer" />

                      <div className="select-container-1 specific-modal">
                        <MultipleSelectComponent
                          dataTestid="specific"
                          name="specific"
                          placeholder="Specific"
                          title="Specific"
                          options={getSpecificOptions()}
                          keyItem={'key'}
                          valueItem={'name'}
                          multiple={false}
                          size={'179px'}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  {getDosageValues()?.dosageRequired && (
                    <div className="product-edit-layer-1">
                      <div>
                        Dosage Unit
                        <div className="dosage-unit-edit">{getDosageValues()?.dosageValue}</div>
                      </div>
                    </div>
                  )}
                  <div className="product-edit-layer-1">
                    <div className="product-edit-row">
                      <MultipleSelectComponent
                        data-testid="strain-type"
                        name="strain_type"
                        placeholder="Strain Type"
                        title="Strain Type"
                        options={StrainTypes}
                        keyItem={'key'}
                        valueItem={'name'}
                        multiple={false}
                        size={'179px'}
                        required={false}
                      />

                      <div className="product-spacer" />

                      <TextInputComponent
                        name="strain"
                        data-testid="strain"
                        placeholder="strain"
                        title="Strain"
                        size={'179px'}
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="product-edit-row">
                    <TextInputComponent
                      name="flavor"
                      placeholder="Flavour"
                      title="Flavour"
                      size={'366px'}
                      required={false}
                    />
                  </div>
                </div>
              </div>
              <div className="product-edit-section-3">
                <div className="product-edit-row-cannabinoid">
                  {cannabinoidLabResultsValue?.map((item: ILabResults, index: number) => {
                    const maxValue = item.max !== null ? `${item.max}` : '';
                    const minValue = item.min !== null ? `${item.min}` : '';
                    const retailData = getJointlyCannabinoid().retail;
                    const indexRetailData = retailData.findIndex(
                      (retailDataItem) => item.name === retailDataItem.name
                    );

                    let label = '';
                    if (indexRetailData !== -1) {
                      if (
                        retailData[indexRetailData].min !== 0 &&
                        retailData[indexRetailData].max !== 0
                      ) {
                        label =
                          retailData[indexRetailData].min === retailData[indexRetailData].max
                            ? `${retailData[indexRetailData].min}`
                            : `${retailData[indexRetailData].min}-${retailData[indexRetailData].max}`;
                      }
                    }

                    const isSmokeable = metricMeasurements.smokeable.find(
                      (item) => item === productType
                    );

                    return (
                      <div key={index}>
                        <div className="textfield-container-1" style={{ width: '100px' }}>
                          <Typography.Text data-testid={`cannabinoid-item-${item.name}`}>{`${
                            item.name
                          } ${isSmokeable ? '%' : 'mg'}`}</Typography.Text>
                          <input
                            type="number"
                            placeholder={`min`}
                            value={minValue}
                            onChange={(e) =>
                              handleChangeCannabinoid(item.name, e.target.value, 'min')
                            }
                            className="input-lab-range"
                            onBlur={() => handleBlurCannabinoid(item.name)}
                            data-testid={`cannabinoid-min-${item.name}`}
                          />
                        </div>

                        <div className="textfield-container-1" style={{ width: '100px' }}>
                          <input
                            type="number"
                            placeholder={`max`}
                            value={maxValue}
                            onChange={(e) =>
                              handleChangeCannabinoid(item.name, e.target.value, 'max')
                            }
                            className="input-lab-range"
                            data-testid={`cannabinoid-max-${item.name}`}
                          />
                        </div>
                        {label !== '' && <p className="lab-avg">{label}</p>}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="product-edit-section-3">
                <div className="product-edit-row-cannabinoid">
                  {terpeneLabResultsValue?.map((item: ILabResults, index: number) => {
                    const maxValue = item.max !== null ? `${item.max}` : '';
                    const minValue = item.min !== null ? `${item.min}` : '';
                    const retailData = getJointlyTerpene().retail;
                    const indexRetailData = retailData.findIndex(
                      (retailDataItem) => item.name === retailDataItem.name
                    );
                    let label = '';
                    if (indexRetailData !== -1) {
                      if (
                        retailData[indexRetailData].min !== 0 &&
                        retailData[indexRetailData].max !== 0
                      ) {
                        label =
                          retailData[indexRetailData].min === retailData[indexRetailData].max
                            ? `${retailData[indexRetailData].min}`
                            : `${retailData[indexRetailData].min}-${retailData[indexRetailData].max}`;
                      }
                    }

                    return (
                      <div key={index}>
                        <div className="textfield-container-1" style={{ width: '100px' }}>
                          <Typography.Text
                            data-testid={`terpene-item-${item.name}`}
                          >{`${item.name}`}</Typography.Text>
                          <input
                            type="number"
                            placeholder={`min`}
                            value={minValue}
                            onChange={(e) => handleChangeTerpene(item.name, e.target.value, 'min')}
                            className="input-lab-range"
                            onBlur={() => handleBlurTerpene(item.name)}
                            data-testid={`terpene-min-${item.name}`}
                          />
                        </div>

                        <div className="textfield-container-1" style={{ width: '100px' }}>
                          <input
                            type="number"
                            placeholder={`max`}
                            value={maxValue}
                            onChange={(e) => handleChangeTerpene(item.name, e.target.value, 'max')}
                            className="input-lab-range"
                            data-testid={`terpene-max-${item.name}`}
                          />
                        </div>
                        {label !== '' && (
                          <p data-testid={`lab-avg-${item.name}`} className="lab-avg">
                            {label}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Typography.Text>JSON Product Data</Typography.Text>
            <Form.Item name="JSON_product_data">
              <TextArea
                className="text-field-sub-component"
                autoSize={{ minRows: 13, maxRows: 13 }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductEditForm;
