import { getBrands } from '../../../../services/api/brandAPI/getActiveBrands';
import { useQuery } from '@tanstack/react-query';
import { Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

type Props = {
  onSelect: (brand: { value: string; label: string }) => void;
  defaultBrand?: string;
};

const BrandSelect = ({ onSelect, defaultBrand }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [internalInputValue, setInternalInputValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [scrollToBottomCount, setScrollToBottomCount] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ['brands', { searchKey: internalInputValue, skip: 0, limit }],
    queryFn: getBrands,
    enabled: !!internalInputValue,
    keepPreviousData: true,
  });

  // default brand feature
  useQuery({
    queryKey: ['brands', { searchKey: defaultBrand, skip: 0, limit: 1 }],
    queryFn: getBrands,
    enabled: !!defaultBrand,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.list && data.list.length > 0) {
        onSelect({ value: data?.list[0].id, label: data?.list[0].name });
        setInputValue(data?.list[0].name);
      }
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputValue !== internalInputValue && inputValue !== '') {
        setInternalInputValue(inputValue);
      }
      if (inputValue === '') {
        setLimit(10);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [internalInputValue, inputValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollToBottomCount > 0) {
        setLimit((prev) => prev + 10);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [setLimit, scrollToBottomCount]);

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
      setScrollToBottomCount((prev) => prev + 1);
    }
  };

  return (
    <div style={{ padding: 5, paddingRight: 10 }}>
      <Select
        showSearch
        placeholder="Search brands"
        optionFilterProp="label"
        style={{ width: '100%' }}
        onChange={(value, option) => {
          if (Array.isArray(option)) {
            onSelect(option[0]);
          } else {
            onSelect(option);
          }
        }}
        onSearch={(value) => setInputValue(value)}
        options={
          data
            ? data?.list.map((brand) => ({
                value: brand.id,
                label: brand.name,
              }))
            : []
        }
        notFoundContent={isLoading && inputValue !== '' ? <Spin size="small" /> : null}
        onPopupScroll={handlePopupScroll}
        searchValue={inputValue}
      />
    </div>
  );
};

export default BrandSelect;
