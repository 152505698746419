import { statusIconSuccess } from '../utils/enum';
import { IAvailableHours } from '../utils/interfaces/available_hours';
import { isJsonString, Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, doAction } from 'module-reaction';
import { EligibleZipCode } from '../views/retailLocation/retail_location_modal/RetailLocationModal';
import { Menu } from '../views/retailLocation/retail_location_modal/components/menu';

const MODULE_PRODUCT = 'MODULE_PRODUCT';
const MODULE_PAGE_LOADING = 'MODULE_PAGE_LOADING';

interface ModelPageLoading extends ModuleStore {
  pageLoading: boolean;
  filterLoading: boolean;
}

export const model_product: any = {
  module: MODULE_PRODUCT,
  list: [],
  total: 0,
  pageSize: 20,
  pageIdx: 1,
  lastUploadCsvStatus: {
    status: 'success',
    icon: statusIconSuccess,
  },
  loading: true,
  hasMore: true,
};

export const model_page_loading: ModelPageLoading = {
  module: MODULE_PAGE_LOADING,
  pageLoading: false,
  filterLoading: false,
};

export const FetchRetailLocationAction: ModuleAction<KV, any> = {
  module: MODULE_PRODUCT,
  name: 'FetchRetailLocationAction',
  maxProcessSeconds: 30,
  process: async (payload: KV) => {
    const { pageSize, pageIdx, search_key, store_url, states } = Object.assign({}, payload);
    doAction(PageLoadingAction, {
      pageLoading: true,
    });
    const res = await Net.req(
      '/org/v1/location/',
      {
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        searchKey: search_key && search_key?.length > 0 ? search_key.trim() : '',
        store_url: store_url && store_url?.length > 0 ? store_url.trim() : '',
        states: states && states?.length > 0 ? states : '',
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: res.list.map((item: any) => ({
          ...item,
          covers: isJsonString(item.covers) ? JSON.parse(item.covers) : item.covers,
        })),
        total: res.total,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

const PageLoadingAction: ModuleAction<KV, ModelPageLoading> = {
  module: MODULE_PAGE_LOADING,
  name: 'PageLoadingAction',
  maxProcessSeconds: 30,
  process: async (payload: KV, model: ModelPageLoading) => {
    const { pageLoading, filterLoading } = Object.assign({}, model, payload);
    return {
      pageLoading,
      filterLoading,
    };
  },
};

export interface Promotion {
  type: string;
  valueType: 'number' | 'boolean';
  value: number | boolean;
}

export interface RetailLocation {
  id: string;
  subscription: string;
  name: string;
  phoneNumber: string;
  isOnlineStore: true;
  pickup: boolean;
  delivery: boolean;
  mobileEnabled: boolean;
  mobWebEnabled: boolean;
  isMedicalOnly: boolean;
  bccEmail: string;
  address?: string;
  website: string;
  city?: string;
  state?: string;
  zipcode?: string;
  email: string;
  totalProducts: number;
  pos: {
    name: string;
    type: string;
    stockMinimum: number;
    connectionInfo: any;
    menus: Menu[];
    customerSearchType: string;
    customerSearchBy: string[];
    disabled: boolean;
    lastSyncAttempt: {
      status: string;
      message: string;
      timestamp: string;
    };
  };
  retailOrganization: {
    id: string;
    name: string;
  };
  geoLocation?: {
    type: string;
    coordinates: number[];
  };
  promotions?: Promotion[];
  pickupHours?: IAvailableHours;
  deliveryHours?: IAvailableHours;
  logoUrl?: string;
  bannerUrl?: string;
  slug?: string;
  preOrderAdvanceDays?: number;
  defaultDeliveryWindow?: string;
  eligibleZipCodes?: EligibleZipCode[];
  deliveryWindow?: number;
  paymentMethods?: string[];
  pickupPaymentMethods?: string[];
  isPhotoIdRequired?: boolean;
  daysOff?: string[];
  photoIdInstruction?: string;
  pricesIncludeTaxes: boolean;
  googlePlaceId?: string;
}
