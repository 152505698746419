import { PageLoadingAction } from './model_product';
import { User } from './model_user';
import { statusIconSuccess, statusIconProcessing, statusIconFail } from '../utils/enum';
import { Net } from '../utils/utils';
import { message } from 'antd';
import { ModuleStore, ModuleAction, KV, doAction, plusAction } from 'module-reaction';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export interface Brand {
  id: string;
  inner_id: string;
  covers: Doc[];
  name: string;
  company: string;
  introduction: string;
  note: string;
  legal_states: string[];
  edit?: boolean;
  updatedAt?: string;
}
export interface Cannabinoid {
  name: string;
  key: string;
}

const MODULE_BRAND = 'MODULE_BRAND';
const MODULE_PENDING_BRAND = 'MODULE_PENDING_BRAND';
const MODULE_DISCONTINUED_BRAND = 'MODULE_DISCONTINUED_BRAND';

interface ModelBrand extends ModuleStore {
  list: Brand[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  lastUploadCsvStatus: {
    status: string; // processing/success/error:msg
    icon: React.ReactNode;
  };
  loading: boolean;
  hasMore: boolean;
}

export const model_brand: ModelBrand = {
  module: MODULE_BRAND,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  lastUploadCsvStatus: {
    status: 'success',
    icon: statusIconSuccess,
  },
  loading: true,
  hasMore: true,
};

export interface PendingBrand {
  id: string;
  inner_id: string;
  covers: Doc[];
  name: string;
  company: string;
  introduction: string;
  note: string;
  user: User;
  submitted_at: Date;
  completed_at: Date;
  legal_states: string[];
}

export interface BrandListProp extends ModelPendingBrand, RouteComponentProps {
  list: PendingBrand[]; // current result for show
  total: number; // total mat
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 1, when fetch, skip=pageSize * pageIdx
  pageLoading: boolean; //show loading until next page is loaded
  selectedBrands?: string[];
}

export interface ModelPendingBrand extends ModuleStore {
  list: PendingBrand[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  loading: boolean;
  hasMore: boolean;
}

interface ModelDiscontinuedBrand extends ModuleStore {
  list: PendingBrand[]; // current result for show
  total: number; // total num of current search
  searchKey: string; // search keyword
  pageSize: number; // when fetch, pageSize=limit
  pageIdx: number; //start with 0, when fetch, skip=pageSize * pageIdx
  loading: boolean;
  hasMore: boolean;
}

export const model_pending_brand: ModelPendingBrand = {
  module: MODULE_PENDING_BRAND,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  loading: true,
  hasMore: true,
};

export const model_discontinued_brand: ModelDiscontinuedBrand = {
  module: MODULE_DISCONTINUED_BRAND,
  list: [],
  total: 0,
  searchKey: '',
  pageSize: 20,
  pageIdx: 1,
  loading: true,
  hasMore: true,
};

export const FetchPendingBrandsAction: ModuleAction<KV, ModelPendingBrand> = {
  module: MODULE_PENDING_BRAND,
  name: 'FetchPendingBrandsAction',
  process: async (payload: KV, model: ModelPendingBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/brand/pending/admin-search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

export const EmptyPendingBrandsAction: ModuleAction<KV, ModelPendingBrand> = {
  module: MODULE_PENDING_BRAND,
  name: 'EmptyPendingBrandsAction',
  process: async () => ({
    searchKey: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchDiscontinuedBrandsAction: ModuleAction<KV, ModelDiscontinuedBrand> = {
  module: MODULE_DISCONTINUED_BRAND,
  name: 'FetchDiscontinuedBrandsAction',
  process: async (payload: KV, model: ModelDiscontinuedBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/brand/admin-search',
      {
        search_key: searchKey ? searchKey : '',
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        status: 'discontinued',
        sort_by,
        order,
      },
      'get'
    );

    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

export const EmptyDiscontinuedBrandsAction: ModuleAction<KV, ModelDiscontinuedBrand> = {
  module: MODULE_DISCONTINUED_BRAND,
  name: 'EmptyDiscontinuedBrandsAction',
  process: async () => ({
    searchKey: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchMoreDiscontinuedBrandsAction: ModuleAction<KV, ModelDiscontinuedBrand> = {
  module: MODULE_DISCONTINUED_BRAND,
  name: 'FetchMoreDiscontinuedBrandsAction',
  process: async (payload: KV, model: ModelDiscontinuedBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/brand/admin-search',
      {
        search_key: searchKey ? searchKey : '',
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        status: 'discontinued',
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, { pageLoading: false });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const FetchMorePendingBrandsAction: ModuleAction<KV, ModelPendingBrand> = {
  module: MODULE_PENDING_BRAND,
  name: 'FetchMorePendingBrandsAction',
  process: async (payload: KV, model: ModelPendingBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/brand/pending/admin-search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const FetchBrandsAction: ModuleAction<KV, ModelBrand> = {
  module: MODULE_BRAND,
  name: 'FetchBrandsAction',
  process: async (payload: KV, model: ModelBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/brand/search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, {
      pageLoading: false,
      filterLoading: false,
    });
    if (res.list && res.list.length) {
      return {
        list: [...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [], total: 0, loading: false, hasMore: false };
  },
};

export const EmptyBrandsAction: ModuleAction<KV, ModelBrand> = {
  module: MODULE_BRAND,
  name: 'EmptyBrandsAction',
  process: async (payload: KV, model: ModelBrand) => ({
    searchKey: '',
    list: [],
    total: 0,
    loading: true,
    pageIdx: 1,
    hasMore: true,
  }),
};

export const FetchMoreBrandsAction: ModuleAction<KV, ModelBrand> = {
  module: MODULE_BRAND,
  name: 'FetchMoreBrandsAction',
  process: async (payload: KV, model: ModelBrand) => {
    const { searchKey, pageSize, pageIdx, sort_by, order } = Object.assign({}, model, payload);
    doAction(PageLoadingAction, { pageLoading: true });

    const res = await Net.req(
      '/brand/search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
        sort_by,
        order,
      },
      'get'
    );
    doAction(PageLoadingAction, { pageLoading: false });
    if (res.list && res.list.length) {
      return {
        list: [...model.list, ...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        loading: false,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0, hasMore: false };
  },
};

export const SearchBrandsAction: ModuleAction<KV, ModelBrand> = {
  module: MODULE_BRAND,
  name: 'SearchBrandsAction',
  process: async (payload: KV, model: ModelBrand) => {
    const { searchKey, pageSize, pageIdx } = Object.assign({}, model, payload);

    const res = await Net.req(
      '/brand/search',
      {
        search_key: searchKey,
        limit: pageSize,
        skip: pageSize * (pageIdx - 1),
      },
      'get'
    );
    if (res.list && res.list.length) {
      return {
        list: [...res.list],
        total: res.total,
        searchKey,
        pageSize,
        pageIdx,
        hasMore: res.list.length === pageSize,
      };
    }
    message.info('No results');
    return { list: [...model.list], total: 0 };
  },
};

export const PollingUploadBrandStatusAction: ModuleAction<string, ModelBrand> = {
  module: MODULE_BRAND,
  name: 'PollingUploadBrandStatusAction',
  process: async (record: string, model: ModelBrand) => {
    const res = await Net.req('/brand/admin-upload-csv-status', { record }, 'post');
    if (res?.status) {
      if (res.status === 'success') {
        // change status and stop polling
        if (record) {
          // if has record, means the upload acted by current user
          // so toast a msg when status change
          message.info('last upload csv success');
        }
        // refresh list
        plusAction(FetchBrandsAction);
        return {
          lastUploadCsvStatus: {
            status: res.status,
            icon: statusIconSuccess,
          },
        };
      } else if (res.status === 'processing') {
        // make another polling 1 min later
        setTimeout(() => {
          doAction(PollingUploadBrandStatusAction, record);
        }, 20 * 1000);
        return {
          lastUploadCsvStatus: {
            status: res.status,
            icon: statusIconProcessing,
          },
        };
      }
      if (record) {
        // if has record, means the upload acted by current user
        // so toast a msg when status change
        message.error('last upload csv error');
      }
      // refresh list
      plusAction(FetchBrandsAction);
      return {
        lastUploadCsvStatus: {
          status: res.status,
          icon: statusIconFail,
        },
      };
    }
    return {};
  },
};
