import { getGooglePlaces, IGPlace } from '../../services/api/retailLocationAPI';
import { useQuery } from '@tanstack/react-query';
import { Modal, Input, Button, List, Spin, Form } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  visible: boolean;
  onRequestClose: () => void;
  onSelectPlace: (selectedPlace: IGPlace) => void; // Add this prop to pass the selected place ID to the parent component
}

const GetFromGooglePlacesModal: React.FC<Props> = ({ visible, onRequestClose, onSelectPlace }) => {
  const [textQuery, setTextQuery] = useState('');
  const [placeID, setPlaceID] = useState('');
  const [selectedPlace, setSelectedPlace] = useState<IGPlace | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const { data: results, refetch } = useQuery(
    ['googlePlaces', textQuery, placeID],
    () => getGooglePlaces({ textQuery, placeID }),
    {
      enabled: false, // Disable automatic query execution
      onSettled: () => setIsFetching(false), // Reset fetching state when query is settled
    }
  );

  useEffect(() => {
    if (results && results.length === 1) {
      setSelectedPlace(results[0]);
    }
  }, [results]);

  const handleSearch = () => {
    setPlaceID(''); // Clear placeId to ensure search results are shown
    setIsFetching(true);
    refetch();
  };

  const handleFetchByPlaceId = () => {
    setTextQuery(''); // Clear query to ensure place ID result is shown
    setIsFetching(true);
    refetch();
  };

  const handleSelectPlace = (place: IGPlace) => {
    setSelectedPlace(place);
  };

  const handleClose = () => {
    setTextQuery('');
    setPlaceID('');
    setSelectedPlace(null);
    onRequestClose();
  };

  const handleConfirmSelection = async () => {
    if (selectedPlace) {
      if (placeID && placeID !== '') {
        // If fetched by placeID, pass the selected place object directly
        onSelectPlace(selectedPlace);
      } else {
        // If fetched by textQuery, fetch the full place details using the placeID
        const fullPlaceDetails: IGPlace[] = await getGooglePlaces({ placeID: selectedPlace.id });
        onSelectPlace(fullPlaceDetails[0]);
      }
      handleClose();
    }
  };

  return (
    <Modal title="Search Google Places" visible={visible} onCancel={handleClose} footer={null}>
      <Form layout="vertical">
        <Form.Item
          label="Search by Name and Location"
          extra="Please type the business' name and location to search for it in Google Places."
        >
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 100px)' }}
              placeholder="Enter place name and address"
              value={textQuery}
              onChange={(e) => setTextQuery(e.target.value)}
            />
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="Fetch by Place ID"
          help="If you know the Place ID from Google, you may type it here."
          style={{ marginTop: '20px' }}
        >
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 168px)' }}
              placeholder="Enter place name and address"
              value={placeID}
              onChange={(e) => setPlaceID(e.target.value)}
            />
            <Button type="primary" onClick={handleFetchByPlaceId}>
              Fetch by Place ID
            </Button>
          </Input.Group>
        </Form.Item>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isFetching ? (
          <Spin size="large" />
        ) : (
          <List
            style={{ marginTop: '20px', width: '100%' }}
            bordered
            dataSource={results}
            renderItem={(item: IGPlace) => (
              <List.Item
                onClick={() => handleSelectPlace(item)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedPlace?.id === item.id ? '#e6f7ff' : 'white',
                }}
              >
                <List.Item.Meta title={item.name} description={item.address} />
              </List.Item>
            )}
          />
        )}
      </div>
      {selectedPlace && (
        <Button type="primary" onClick={handleConfirmSelection} style={{ marginTop: '20px' }}>
          Confirm Selection
        </Button>
      )}
    </Modal>
  );
};

export default GetFromGooglePlacesModal;
