import { CameraOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState, useCallback, MouseEvent, useEffect } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

type ImagePicker = {
  url?: string;
  onChange?: (file: File | null) => void;
  label?: string;
  format?: 'square' | 'rectangle';
};

/**
 * For now it is coupled with the retailLocation form, but the image uploader can be abstracted in the future
 */
const ImagePicker: React.FC<ImagePicker> = ({
  url = undefined,
  onChange,
  label,
  format = 'square',
}) => {
  const [pickedImage, setPickedImage] = useState<string | undefined>(url);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setPickedImage(url);
  }, [url]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      setPickedImage(URL.createObjectURL(file));
      onChange?.(file);
    },
    [onChange]
  );

  const handleClear = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickedImage(undefined);
    onChange?.(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
  });

  const rootProps: DropzoneRootProps = getRootProps();
  const inputProps: DropzoneInputProps = getInputProps();

  return (
    <div {...rootProps}>
      <input {...inputProps} />
      {label && <p>{label}</p>}

      <div
        data-testid="dropzone"
        style={{
          width: format === 'rectangle' ? '100%' : '100px',
          height: '100px',
          border: `2px ${!pickedImage ? 'dashed' : 'solid'} ${isDragActive ? '#5344ff' : '#ccc'}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: '4px',
          borderRadius: '4px',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {(!pickedImage || hover) && (
          <Tooltip title="Drag 'n' drop an image here, or click to select an image">
            <CameraOutlined
              data-testid="camera-icon"
              style={{
                fontSize: 48,
                color: '#ccc',
                position: 'absolute',
                filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.5))',
              }}
            />
          </Tooltip>
        )}
        {pickedImage && hover && (
          <Tooltip title="Clear Image">
            <Button
              data-testid="clear-image-button"
              icon={
                <CloseOutlined
                  style={{
                    filter: 'drop-shadow(0px 0px 1px #FFF)',
                  }}
                />
              }
              type="text"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={handleClear}
            />
          </Tooltip>
        )}
        {pickedImage && (
          <img
            referrerPolicy="no-referrer"
            data-testid="uploaded-image"
            src={pickedImage}
            alt="Uploaded"
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ImagePicker;
