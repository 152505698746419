import { Promotion, RetailLocation } from '../../../models/model_retail_location_paginated';
import axios from '../../apiClient';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getRetailLocation = async (id: string): Promise<RetailLocation> => {
  const { data } = await axios.get('/org/v1/location/' + id);
  return data.retailLocation;
};

export const getRetailLocations = async (params: any): Promise<RetailLocation[]> => {
  const urlParams = new URLSearchParams();
  (Object.entries(params) as Entries<typeof params>).forEach(([key, value]) => {
    urlParams.set(key, value);
  });
  urlParams.set('limit', '1000');
  const { data } = await axios.get('/org/v1/location?' + urlParams.toString());
  return data.list;
};

export interface AddRetailLocation {
  name: string;
  isOnlineStore: boolean;
  pickup?: boolean;
  delivery?: boolean;
  mobileEnabled?: boolean;
  mobWebEnabled?: boolean;
  isMedicalOnly?: boolean;
  bccEmail?: string;
  address?: string;
  website?: string;
  phoneNumber: string;
  state?: string;
  zipcode?: string;
  geoLocation?: {
    type: string;
    coordinates: number[];
  };
  retailOrganizationId?: string;
  pos: {
    name: string;
    type: string;
    stockMinimum: number;
    connectionInfo: unknown;
    customerSearchType: string;
    customerSearchBy: string[];
  };
  promotions?: Promotion[];
  slug?: string;
  googlePlaceId?: string;
}

export interface EditRetailLocation extends AddRetailLocation {
  id: string;
  retailOrganization: {
    id: string;
    name?: string;
  };
}

export const editRetailLocation = async (payload: EditRetailLocation) =>
  axios.put('/org/v1/location', payload);

export const addRetailLocation = async (payload: AddRetailLocation) =>
  axios.post('/org/v1/location', payload);

export type InstallRetailLocationPayload = {
  id: string;
};

export const installRetailLocation = async (payload: InstallRetailLocationPayload) =>
  axios.post('/org/v1/location/sync', payload);

// Typescript interfaces used for places information coming from /org/v2/location/gPlaceDetail and /org/v2/location/gPlacesByText
export interface addressComponent {
  types: string[];
  shortText: string;
  longText: string;
  languageCode: string;
}

export interface IGPlace {
  id: string;
  name: string;
  address: string;
  addressComponents?: addressComponent[];
  phoneNumber?: string;
  website?: string;
  geoLocation?: {
    coordinates?: number[];
  };
  photos?: {
    name: string;
    widthPx: number;
    heightPx: number;
    authorAttributions: {
      displayName: string;
      uri: string;
      photoUri: string;
    }[];
    flagContentUri: string;
    googleMapsUri: string;
  }[];
  currentOpeningHours?: { periods: { open: { day: number; time: string } }[] };
  currentSecondaryOpeningHours?: { periods: { open: { day: number; time: string } }[] }[];
  regularOpeningHours?: { periods: { open: { day: number; time: string } }[] };
  regularSecondaryOpeningHours?: { periods: { open: { day: number; time: string } }[] }[];
}

// Function to fetch Google Places information from Jointly API-Server
export const getGooglePlaces = async ({
  textQuery,
  placeID,
}: {
  textQuery?: string;
  placeID?: string;
}): Promise<IGPlace[]> => {
  let response;
  let data;
  if (placeID) {
    response = await axios.get(`/org/v2/location/gPlaceDetail/${placeID}`);
    data = [response?.data];
  } else if (textQuery) {
    response = await axios.get(`/org/v2/location/gPlacesByText?textQuery=${textQuery}`);
    data = response?.data;
  }
  return data?.[0]?.id ? data : [];
};
